enum Action {
  AddProductsToCart = 'add-products-to-cart',
  AddProductsToCart2 = 'addProductsToCart', // For backward compatibility.
  ConfirmEmail = 'confirm-email',
  CreateGreenFridayReminder = 'create-green-friday-reminder',
  ResetPassword = 'reset-password',
  ViewRecommendation = 'view-recommendation',
  ViewRecommendation2 = 'viewRecommendation' // For backward compatibility.
}

export default Action
